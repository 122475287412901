import React, { useState } from "react";
import {
  CheckCircleIcon,
  XMarkIcon,
  PlusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SparklesIcon,
  Bars3Icon,
  TrashIcon,
  ListBulletIcon,
  BoltIcon,
  BeakerIcon,
  StopCircleIcon,
  MicrophoneIcon,
  Cog6ToothIcon,
  ArchiveBoxXMarkIcon,
  ExclamationCircleIcon,
  KeyIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  LightBulbIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import APIKeyManager from "./APIKeyManager";
import { useAuth } from "./authContext";
import { SubscribeModal } from "./SubscribeModal";
import { SharedGenerationsPrompt } from './SharedGenerationsPrompt';
import Switch from './Switch';

const Navbar = ({
  isCollapsed,
  setIsCollapsed,
  mainTopic,
  setMainTopic,
  contextDocument,
  handleContextDocumentChange,
  topics,
  setTopics,
  editTopic,
  setEditTopic,
  handleAddTopic,
  handleTopicChange,
  handleRemoveTopic,
  handleDragStart,
  handleDrop,
  generateQuestions,
  setGenerateQuestions,
  generateFallacies,
  setGenerateFallacies,
  generateNuggets,
  setGenerateNuggets,
  handleGenerateClick,
  resetSuggestions,
  clearTranscript,
  goToPreviousTopic,
  goToNextTopic,
  currentTopicIndex,
  progressPercentage,
  cooldown,
  setIsValidApiKey,
  isValidApiKey,
  isAnySourceActive,
  stopBothSources,
  initiateStartProcess,
  isStarting,
  setupCompleted,
  handleSubscribe,
  generateNotes,
  setGenerateNotes,
  remainingGenerations,
  generationsError,
  checkAndIncrementCounter,
}) => {
  const { user, isSubscribed } = useAuth();
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showSecretFeatures, setShowSecretFeatures] = useState(false);
  const [isPasswordPromptOpen, setIsPasswordPromptOpen] = useState(false);
  
  // Add character limits
  const TITLE_MAX_LENGTH = 100;
  const CONTEXT_MAX_LENGTH = 2000;

  // Calculate remaining characters
  const titleRemainingChars = TITLE_MAX_LENGTH - (mainTopic?.length || 0);
  const contextRemainingChars = CONTEXT_MAX_LENGTH - (contextDocument?.length || 0);

  // Add check for analysis options
  const hasAnalysisOptionEnabled = generateQuestions || generateFallacies || generateNuggets || generateNotes;

  // Add this to setupCompleted check
  const allRequiredFieldsComplete = mainTopic &&
    contextDocument &&
    topics.length > 0 &&
    (isValidApiKey || remainingGenerations > 0) &&
    hasAnalysisOptionEnabled;

  const handleButtonClick = async () => {
    if (!isSubscribed) {
      setShowSubscribeModal(true);
    } else {
      handleGenerateClick();
    }
  };

  const handleToggleFallacies = () => {
    try {
      if (!user || !isSubscribed) {
        setGenerateFallacies(false);
        localStorage.setItem("generateFallacies", "false");
        setShowSubscribeModal(true);
        return;
      }
      if (typeof setGenerateFallacies !== 'function') {
        console.error('setGenerateFallacies is not a function');
        return;
      }
      setGenerateFallacies(!generateFallacies);
      localStorage.setItem("generateFallacies", JSON.stringify(!generateFallacies));
    } catch (error) {
      console.error('Error in handleToggleFallacies:', error);
    }
  };

  const handleToggleNuggets = () => {
    try {
      if (!user || !isSubscribed) {
        setGenerateNuggets(false);
        localStorage.setItem("generateNuggets", "false");
        setShowSubscribeModal(true);
        return;
      }
      if (typeof setGenerateNuggets !== 'function') {
        console.error('setGenerateNuggets is not a function');
        return;
      }
      setGenerateNuggets(!generateNuggets);
      localStorage.setItem("generateNuggets", JSON.stringify(!generateNuggets));
    } catch (error) {
      console.error('Error in handleToggleNuggets:', error);
    }
  };

  // Add this handler to truncate the title if needed
  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length <= TITLE_MAX_LENGTH) {
      setMainTopic(value);
    }
  };

  // Add this handler to truncate the context if needed
  const handleContextChange = (e) => {
    const value = e.target.value;
    if (value.length <= CONTEXT_MAX_LENGTH) {
      handleContextDocumentChange(e);
    }
  };

  const handleSecretToggle = () => {
    if (!showSecretFeatures) {
      setIsPasswordPromptOpen(true);
    } else {
      setShowSecretFeatures(false);
    }
  };

  const handlePasswordSubmit = (password) => {
    if (password === "1234") {
      setShowSecretFeatures(true);
      setIsPasswordPromptOpen(false);
    } else {
      alert("Incorrect password");
    }
  };

  return (
    <>
      {/* Settings Panel Backdrop */}
      <div
        className={`fixed inset-0 bg-black/50 backdrop-blur-sm z-[30] transition-opacity duration-300 ${isCollapsed ? "opacity-0 pointer-events-none" : "opacity-100"
          }`}
        onClick={() => setIsCollapsed(true)}
      >
        {/* Settings Panel Card */}
        <div
          className={`fixed left-1/2 top-0 md:bottom-24 md:top-auto -translate-x-1/2 w-full max-w-2xl bg-white dark:bg-neutral-900 rounded-none md:rounded-xl shadow-xl transform transition-transform duration-300 h-[100vh] md:h-auto ${isCollapsed ? "translate-y-full" : "translate-y-0"
            }`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header - Sticky */}
          <div className="sticky top-0 z-10 flex items-center justify-between p-4 border-b border-neutral-300 dark:border-neutral-600 bg-white dark:bg-neutral-900 rounded-none md:rounded-t-xl">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-neutral-100">Settings</h2>
            <button
              onClick={() => setIsCollapsed(true)}
              className="text-gray-500 hover:text-gray-700 dark:hover:text-neutral-200"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          {/* Content - Scrollable area */}
          <div className="overflow-y-auto h-[calc(100vh-8rem)] md:max-h-[80vh] px-4">
            <div className="py-4 space-y-6 pb-36">
              {/* Required Fields Notice */}
              <div className="text-sm text-black dark:text-white flex items-center space-x-1">
                <span>Fields marked with</span>
                <span className="text-red-500">*</span>
                <span>are recommended, for better accuracy</span>
              </div>

              {/* API Key Section */}
              <div className="relative">
                <APIKeyManager
                  onApiKeyChange={(key, isValid) => setIsValidApiKey(key, isValid)}
                />
              </div>

              {/* Study Title */}
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <label className="text-sm font-medium text-gray-700 dark:text-neutral-300 flex items-center space-x-1">
                    <span>Title</span>
                    <span className="text-red-500">*</span>
                  </label>
                  <span className={`text-xs ${titleRemainingChars < 20 ? 'text-red-500' : 'text-gray-500 dark:text-neutral-400'}`}>
                    {titleRemainingChars} characters remaining
                  </span>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    className={`w-full p-3 border rounded-lg bg-white dark:bg-neutral-800 text-black dark:text-white focus:ring-2 focus:ring-blue-400 ${
                      !mainTopic ? "border-red-300 dark:border-red-700" : "border-neutral-300 dark:border-neutral-600"
                    }`}
                    value={mainTopic}
                    onChange={handleTitleChange}
                    placeholder="Enter the main topic of your study"
                    maxLength={TITLE_MAX_LENGTH}
                  />
                  {mainTopic && (
                    <CheckCircleIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-5 w-5 text-green-500" />
                  )}
                </div>
              </div>

              {/* Context Document */}
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <label className="text-sm font-medium text-gray-700 dark:text-neutral-300 flex items-center space-x-1">
                    <span>Additional Context</span>
                    <span className="text-red-500">*</span>
                  </label>
                  <span className={`text-xs ${contextRemainingChars < 100 ? 'text-red-500' : 'text-gray-500 dark:text-neutral-400'}`}>
                    {contextRemainingChars} characters remaining
                  </span>
                </div>
                <textarea
                  className={`w-full h-48 p-3 border rounded-lg bg-white dark:bg-neutral-800 text-black dark:text-white focus:ring-2 focus:ring-blue-400 resize-none ${
                    !contextDocument ? "border-red-300 dark:border-red-700" : "border-neutral-300 dark:border-neutral-600"
                  }`}
                  value={contextDocument}
                  onChange={handleContextChange}
                  placeholder="Provide any additional context or background information"
                  maxLength={CONTEXT_MAX_LENGTH}
                />
                <p className="text-xs text-gray-500 dark:text-neutral-400 mt-1">
                  Tip: Keep your context concise and focused on the most relevant information for better results.
                </p>
              </div>

              {/* Subtopics */}
              <div className="space-y-4">
                <div className="flex items-center justify-between pb-2 border-b border-neutral-300 dark:border-neutral-600 text-black dark:text-white">
                  <div className="flex items-center space-x-2">
                    <ListBulletIcon className="h-5 w-5" />
                    <h2 className="font-medium text-gray-700 dark:text-neutral-200">Subtopics</h2>
                  </div>
                  {topics.length === 0 && (
                    <span className="text-xs text-yellow-500">Add at least one subtopic</span>
                  )}
                </div>

                <div className="space-y-2">
                  {topics.map((topic, index) => (
                    <div
                      key={index}
                      className="group flex items-center space-x-2 p-2 border rounded-lg bg-white dark:bg-neutral-800 text-black dark:text-white border-neutral-300 dark:border-neutral-600"
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => handleDrop(e, index)}
                    >
                      <Bars3Icon className="h-5 w-5 text-gray-400" />
                      <input
                        type="text"
                        value={topic}
                        onChange={(e) => handleTopicChange(e, index)}
                        className="flex-1 bg-transparent border-0 focus:ring-0"
                        placeholder="Enter subtopic"
                      />
                      <button
                        onClick={() => handleRemoveTopic(index)}
                        className="text-gray-400 hover:text-red-500"
                      >
                        <XMarkIcon className="h-5 w-5" />
                      </button>
                    </div>
                  ))}

                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={editTopic}
                      onChange={(e) => setEditTopic(e.target.value)}
                      placeholder="Add new subtopic..."
                      className="flex-1 p-3 border rounded-lg bg-white dark:bg-neutral-800 text-black dark:text-white border-neutral-300 dark:border-neutral-600 focus:ring-2 focus:ring-blue-400"
                    />
                    <button
                      onClick={handleAddTopic}
                      className="p-3 border border-neutral-300 dark:border-neutral-600 rounded-lg text-black dark:text-white dark:text-neutral-300 hover:text-green-500 hover:border-green-500 dark:hover:text-green-500 dark:hover:border-green-500 hover:text-blue-500 bg-white dark:bg-neutral-800"
                    >
                      <PlusIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Options */}
              <div className="space-y-6">
                {/* Live Analysis Tools */}
                <div className="rounded-lg border border-blue-100 dark:border-blue-900 bg-blue-50/50 dark:bg-blue-950/50 p-4">
                  <div className="flex items-center space-x-2 mb-4">
                    <BoltIcon className="h-5 w-5 text-blue-600 dark:text-blue-500" />
                    <h2 className="font-semibold text-blue-900 dark:text-blue-100">Live Analysis</h2>
                  </div>

                  {/* Notes */}
                  <div className="space-y-4">
                    <div className="flex items-center justify-between p-3 bg-white dark:bg-neutral-800 rounded-lg border border-neutral-200 dark:border-neutral-700">
                      <div className="flex items-start space-x-3">
                        <DocumentTextIcon className="h-5 w-5 text-blue-600 dark:text-blue-500 mt-1" />
                        <div>
                          <label className="text-sm font-medium text-gray-900 dark:text-neutral-100">
                            Smart Notes
                          </label>
                          <p className="text-xs text-gray-600 dark:text-neutral-400">
                            Creates brief, single-sentence summaries of key discussion points
                          </p>
                        </div>
                      </div>
                      <Switch type="note" checked={generateNotes} onChange={setGenerateNotes} />
                    </div>

                    {/* Questions */}
                    <div className="flex items-center justify-between p-3 bg-white dark:bg-neutral-800 rounded-lg border border-neutral-200 dark:border-neutral-700">
                      <div className="flex items-start space-x-3">
                        <QuestionMarkCircleIcon className="h-5 w-5 text-blue-600 dark:text-blue-500 mt-1" />
                        <div>
                          <label className="text-sm font-medium text-gray-900 dark:text-neutral-100">
                            Follow-up Questions
                          </label>
                          <p className="text-xs text-gray-600 dark:text-neutral-400">
                            Suggests relevant questions based on answers that need exploration
                          </p>
                        </div>
                      </div>
                      <Switch type="question" checked={generateQuestions} onChange={setGenerateQuestions} />
                    </div>
                  </div>
                </div>

                {/* Analysis Tools */}
                <div className="rounded-lg border border-purple-100 dark:border-purple-900 bg-purple-50/50 dark:bg-purple-950/50 p-4">
                  <div className="flex items-center space-x-2 mb-4">
                    <BeakerIcon className="h-5 w-5 text-purple-600 dark:text-purple-500" />
                    <h2 className="font-semibold text-purple-900 dark:text-purple-100">Deep Analysis</h2>
                  </div>

                  {/* Nuggets */}
                  <div className="space-y-4">
                    <div className="flex items-center justify-between p-3 bg-white dark:bg-neutral-800 rounded-lg border border-neutral-200 dark:border-neutral-700">
                      <div className="flex items-start space-x-3">
                        <LightBulbIcon className="h-5 w-5 text-purple-600 dark:text-purple-500 mt-1" />
                        <div>
                          <div className="flex items-center space-x-2">
                            <label className="text-sm font-medium text-gray-900 dark:text-neutral-100">
                              Insight Detection
                            </label>
                            <span className={`px-2 py-0.5 text-xs rounded-full ${
                              isSubscribed 
                                ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                                : "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200"
                            }`}>
                              {isSubscribed ? "Premium ✓" : "Premium"}
                            </span>
                          </div>
                          <p className="text-xs text-gray-600 dark:text-neutral-400">
                            Identifies valuable insights and patterns in responses
                          </p>
                        </div>
                      </div>
                      <Switch type="nugget" checked={generateNuggets} onChange={handleToggleNuggets} isSubscribed={isSubscribed} />
                    </div>

                    {/* Fallacies */}
                    <div className="flex items-center justify-between p-3 bg-white dark:bg-neutral-800 rounded-lg border border-neutral-200 dark:border-neutral-700">
                      <div className="flex items-start space-x-3">
                        <ExclamationTriangleIcon className="h-5 w-5 text-purple-600 dark:text-purple-500 mt-1" />
                        <div>
                          <div className="flex items-center space-x-2">
                            <label className="text-sm font-medium text-gray-900 dark:text-neutral-100">
                              Fallacy Detection
                            </label>
                            <span className={`px-2 py-0.5 text-xs rounded-full ${
                              isSubscribed 
                                ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                                : "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200"
                            }`}>
                              {isSubscribed ? "Premium ✓" : "Premium"}
                            </span>
                          </div>
                          <p className="text-xs text-gray-600 dark:text-neutral-400">
                            Spots potential logical fallacies and reasoning errors
                          </p>
                        </div>
                      </div>
                      <Switch type="fallacy" checked={generateFallacies} onChange={handleToggleFallacies} isSubscribed={isSubscribed} />
                    </div>
                  </div>
                </div>

                {/* Add Secret Features section here, after Analysis Tools */}
                <div className="rounded-lg border border-red-100 dark:border-red-900 bg-red-50/50 dark:bg-red-950/50 p-4">
                  <div className="flex items-center space-x-2 mb-4">
                    <KeyIcon className="h-5 w-5 text-red-600 dark:text-red-500" />
                    <h2 className="font-semibold text-red-900 dark:text-red-100">Secret Features</h2>
                  </div>

                  {/* Secret Features Toggle */}
                  <div className="flex items-center justify-between p-3 bg-white dark:bg-neutral-800 rounded-lg border border-neutral-200 dark:border-neutral-700">
                    <div className="flex items-start space-x-3">
                      <KeyIcon className="h-5 w-5 text-red-600 dark:text-red-500 mt-1" />
                      <div>
                        <label className="text-sm font-medium text-gray-900 dark:text-neutral-100">
                          Enable Secret Features
                        </label>
                        <p className="text-xs text-gray-600 dark:text-neutral-400">
                          Unlock additional experimental features
                        </p>
                      </div>
                    </div>
                    <Switch 
                      type="secret" 
                      checked={showSecretFeatures} 
                      onChange={handleSecretToggle} 
                    />
                  </div>

                  {/* Hidden Secret Features */}
                  {showSecretFeatures && (
                    <div className="space-y-4 mt-4">
                      {/* Feature 1 */}
                      <div className="flex items-center justify-between p-3 bg-white dark:bg-neutral-800 rounded-lg border border-neutral-200 dark:border-neutral-700">
                        <div className="flex items-start space-x-3">
                          <SparklesIcon className="h-5 w-5 text-red-600 dark:text-red-500 mt-1" />
                          <div>
                            <label className="text-sm font-medium text-gray-900 dark:text-neutral-100">
                              Secret Feature 1
                            </label>
                            <p className="text-xs text-gray-600 dark:text-neutral-400">
                              Description of secret feature 1
                            </p>
                          </div>
                        </div>
                        <Switch type="secret1" checked={false} onChange={() => {}} />
                      </div>

                      {/* Feature 2 */}
                      <div className="flex items-center justify-between p-3 bg-white dark:bg-neutral-800 rounded-lg border border-neutral-200 dark:border-neutral-700">
                        <div className="flex items-start space-x-3">
                          <BoltIcon className="h-5 w-5 text-red-600 dark:text-red-500 mt-1" />
                          <div>
                            <label className="text-sm font-medium text-gray-900 dark:text-neutral-100">
                              Secret Feature 2
                            </label>
                            <p className="text-xs text-gray-600 dark:text-neutral-400">
                              Description of secret feature 2
                            </p>
                          </div>
                        </div>
                        <Switch type="secret2" checked={false} onChange={() => {}} />
                      </div>

                      {/* Feature 3 */}
                      <div className="flex items-center justify-between p-3 bg-white dark:bg-neutral-800 rounded-lg border border-neutral-200 dark:border-neutral-700">
                        <div className="flex items-start space-x-3">
                          <BeakerIcon className="h-5 w-5 text-red-600 dark:text-red-500 mt-1" />
                          <div>
                            <label className="text-sm font-medium text-gray-900 dark:text-neutral-100">
                              Secret Feature 3
                            </label>
                            <p className="text-xs text-gray-600 dark:text-neutral-400">
                              Description of secret feature 3
                            </p>
                          </div>
                        </div>
                        <Switch type="secret3" checked={false} onChange={() => {}} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Navigation Bar */}
      <div className="fixed z-[50] bottom-0 left-0 right-0 bg-white dark:bg-neutral-800 text-black dark:text-white border-t border-neutral-300 dark:border-neutral-600">
        <div className="max-w-screen-xl mx-auto px-4 py-2">
          <div className="flex items-center justify-between">
            {/* Primary Actions */}
            <div className="flex items-center space-x-3">
              {/* Record/Stop Button */}
              <button
                onClick={isAnySourceActive ? stopBothSources : initiateStartProcess}
                className={`
                  flex items-center space-x-2 px-4 py-2 rounded-lg
                  transition-all duration-200
                  ${isAnySourceActive
                    ? "border border-red-500 hover:border-red-600 text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                    : isStarting
                      ? "bg-gray-100 dark:bg-neutral-700 text-gray-400 dark:text-neutral-500 cursor-not-allowed border border-gray-200 dark:border-neutral-600"
                      : "border border-green-500 hover:border-green-600 text-green-600 hover:text-green-700 dark:text-green-400 dark:hover:text-green-300"
                  }
                  disabled:opacity-60 disabled:cursor-not-allowed disabled:border-gray-200 disabled:dark:border-neutral-600 disabled:text-gray-400 disabled:dark:text-neutral-500
                `}
                disabled={!setupCompleted || isStarting}
              >
                {isAnySourceActive ? (
                  <>
                    <StopCircleIcon className="h-5 w-5" />
                    <span className="hidden sm:inline">Stop</span>
                  </>
                ) : (
                  <>
                    <MicrophoneIcon className="h-5 w-5 text-green-500" />
                    <span className="hidden sm:inline">Start</span>
                  </>
                )}
              </button>

              {/* Generate Button with Status */}
              <div className="flex flex-col items-end">
                <button
                  onClick={handleGenerateClick}
                  disabled={cooldown > 0 || isAnySourceActive || (!isValidApiKey && remainingGenerations === 0)}
                  className={`
                    relative flex items-center space-x-2 px-4 py-2 rounded-lg
                    transition-all duration-200
                    ${(!cooldown && !isAnySourceActive && (isValidApiKey || remainingGenerations > 0))
                      ? "border border-blue-500 hover:border-blue-600 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                      : "bg-gray-100 dark:bg-neutral-700 text-gray-400 dark:text-neutral-500 cursor-not-allowed border border-gray-200 dark:border-neutral-600"
                    }
                    disabled:opacity-60 disabled:cursor-not-allowed
                  `}
                >
                  <SparklesIcon className="h-5 w-5 text-blue-500" />
                  <span className="hidden sm:inline">Generate</span>

                  {/* Status Indicators */}
                  {remainingGenerations === 0 && !isValidApiKey ? (
                    <div className="flex items-center space-x-1 ml-2 text-red-500 dark:text-red-400">
                      <ExclamationCircleIcon className="h-4 w-4" />
                      <span className="text-xs font-medium">Limit reached</span>
                    </div>
                  ) : remainingGenerations > 0 && !isValidApiKey ? (
                    <div className="flex items-center space-x-1 ml-2 text-yellow-500">
                      <span className="text-xs font-medium">{remainingGenerations}</span>
                    </div>
                  ) : isValidApiKey && (
                    <div className="flex items-center space-x-1 ml-2 text-emerald-500">
                      {/* <KeyIcon className="h-4 w-4" /> */}
                      <span className="text-xs">∞</span>
                    </div>
                  )}
                </button>

                {/* API Key CTA - Only show when limit reached */}
                {remainingGenerations === 0 && !isValidApiKey && (
                  <button
                    onClick={() => setIsCollapsed(false)}
                    className="mt-1 text-xs text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 font-medium transition-colors duration-200"
                  >
                    Add API key to continue →
                  </button>
                )}
              </div>
            </div>

            {/* Secondary Actions */}
            <div className="flex items-center">
              {/* Clear Actions */}
              <div className="flex items-center space-x-2 mr-4">
                <button
                  onClick={resetSuggestions}
                  className="
                    flex items-center space-x-2 p-2 rounded-lg
                    text-gray-600 hover:text-yellow-600 dark:text-neutral-400 dark:hover:text-yellow-400
                    hover:bg-yellow-50 dark:hover:bg-yellow-900/20
                    transition-colors duration-200
                  "
                >
                  <ArchiveBoxXMarkIcon className="h-5 w-5" />
                  <span className="hidden text-sm">Suggestions</span>
                </button>
                <button
                  onClick={clearTranscript}
                  className="
                    flex items-center space-x-2 p-2 rounded-lg
                    text-gray-600 hover:text-red-600 dark:text-neutral-400 dark:hover:text-red-400
                    hover:bg-red-50 dark:hover:bg-red-900/20
                    transition-colors duration-200
                  "
                >
                  <TrashIcon className="h-5 w-5" />
                  <span className="hidden text-sm">Transcription</span>
                </button>
              </div>
              {/* Topic Navigation */}
              <div className="flex items-center border-l border-neutral-300 dark:border-neutral-600 pl-4 space-x-1">
                <button
                  onClick={goToPreviousTopic}
                  className="
                    p-2 rounded-lg
                    text-gray-600 hover:text-gray-900 dark:text-neutral-400 dark:hover:text-neutral-200
                    hover:bg-gray-100 dark:hover:bg-neutral-700
                    transition-colors duration-200
                    disabled:opacity-50 disabled:cursor-not-allowed
                  "
                  disabled={currentTopicIndex === 0}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
                <span className="text-sm font-medium text-gray-700 dark:text-neutral-200 min-w-[3ch] text-center">
                  <span className="hidden lg:inline">Page{" "}</span>{currentTopicIndex + 1}/{topics.length}
                </span>
                <button
                  onClick={goToNextTopic}
                  className="p-2 text-black dark:text-white hover:text-gray-900 dark:text-neutral-200"
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>

                {/* Settings Toggle with Alert */}
                <button
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  className="
                    flex items-center space-x-2 p-2 rounded-lg ml-1
                    text-gray-600 hover:text-gray-900 dark:text-neutral-400 dark:hover:text-neutral-200
                    hover:bg-gray-100 dark:hover:bg-neutral-700
                    transition-colors duration-200
                    relative
                  "
                >
                  {isCollapsed ? (
                    <>
                      <div className="relative">
                        <Cog6ToothIcon className="h-5 w-5" />
                        {!allRequiredFieldsComplete && (
                          <div className="absolute -top-1 -right-1 w-3 h-3 bg-yellow-400 dark:bg-yellow-500 rounded-full border border-neutral-300 dark:border-neutral-600" />
                        )}
                      </div>
                      <span className="hidden text-sm">
                        Settings
                        {!allRequiredFieldsComplete && (
                          <span className="ml-1 text-yellow-500 dark:text-yellow-400"></span>
                        )}
                      </span>
                    </>
                  ) : (
                    <XMarkIcon className="h-5 w-5" />
                  )}
                </button>

                {/* {user && (
                  <button
                    onClick={signOutUser}
                    className="p-2 text-black dark:text-white hover:text-gray-900 dark:text-neutral-200"
                    title="Sign Out"
                  >
                    <ArrowRightOnRectangleIcon className="h-5 w-5" />
                  </button>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubscribeModal
        isOpen={showSubscribeModal}
        onClose={() => setShowSubscribeModal(false)}
      />
      {remainingGenerations === 0 && !isValidApiKey && (
        <SharedGenerationsPrompt
          onAddApiKey={() => setIsCollapsed(false)}
        />
      )}

      {/* Password Prompt Modal */}
      {isPasswordPromptOpen && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[60] flex items-center justify-center">
          <div className="bg-white dark:bg-neutral-800 p-6 rounded-lg w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">Enter Secret Password</h3>
            <input
              type="password"
              id="secretPassword"
              className="w-full p-2 border rounded mb-4 dark:bg-neutral-700 dark:border-neutral-600 dark:text-white"
              placeholder="Enter password"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handlePasswordSubmit(e.target.value);
                }
              }}
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsPasswordPromptOpen(false)}
                className="px-4 py-2 text-gray-600 dark:text-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  const password = document.getElementById('secretPassword').value;
                  handlePasswordSubmit(password);
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;