import {
  CheckCircleIcon,
  ArrowDownCircleIcon,
  ClipboardIcon,
  InformationCircleIcon,
  ArrowDownTrayIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import statements from "./Statements";
import LoginButton from "./LoginButton";

const EditorHeader = ({
  hasUnsavedChanges,
  setHasUnsavedChanges,
  saveSuccess,
  copySuccess,
  downloadSuccess,
  onSave,
  onShowPrivacy,
  onDownload,
  onCopy,
  topics,
  currentTopicIndex,
  generateNotes,
  setGenerateNotes,
  showPrivacyModal,
  setShowPrivacyModal
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleSave = () => {
    onSave();
    setHasUnsavedChanges(false);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  return (
    <header className="sticky top-0 z-[10] flex items-center justify-between px-4 py-2 bg-white dark:bg-neutral-800 border-b border-neutral-300 dark:border-neutral-600 shadow-md space-x-1">
      {/* Logo and Statement */}
      <div className="flex items-center space-x-2 min-w-0 max-w-[60%]">
        <div className="overflow-x-auto scrollbar-none">
          <span className="text-sm font-medium text-gray-800 dark:text-white whitespace-nowrap">
            {topics[currentTopicIndex]}
          </span>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex items-center space-x-2 relative flex-shrink-0">
        {/* Save Button */}
        <button
          className="relative p-2 rounded-lg text-gray-600 dark:text-neutral-300 hover:opacity-50 transition"
          onClick={handleSave}
          title={hasUnsavedChanges ? "Unsaved Changes" : "All Changes Saved"}
        >
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 3h11l4 4v12a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 3v5h6V3H9z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 14h10v4H7v-4z"
              />
            </svg>
            {hasUnsavedChanges && (
              <div className="absolute -top-1 -right-1 w-3 h-3 bg-yellow-400 dark:bg-yellow-500 rounded-full" />
            )}
          </div>
        </button>

        {showTooltip && (
          <div className="absolute top-9 mb-2 left-3 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded px-2 py-1 shadow-md">
            Saved!
          </div>
        )}

        <button
          className="p-2 rounded-lg text-gray-600 dark:text-neutral-300 hover:opacity-50 transition"
          onClick={onDownload}
          title="Download Transcript"
        >
          {downloadSuccess ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
          ) : (
            <ArrowDownTrayIcon className="h-5 w-5" />
          )}
        </button>

        <button
          className="p-2 rounded-lg text-gray-600 dark:text-neutral-300 hover:opacity-50 transition"
          onClick={onCopy}
          title="Copy to Clipboard"
        >
          {copySuccess ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
          ) : (
            <ClipboardIcon className="h-5 w-5" />
          )}
        </button>
        <LoginButton 
          generateNotes={generateNotes}
          setGenerateNotes={setGenerateNotes}
          showPrivacyModal={showPrivacyModal}
          setShowPrivacyModal={setShowPrivacyModal}
        />
      </div>
    </header>
  );
};

export default EditorHeader;